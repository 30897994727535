<template>
	<v-container>
		<portal to="app-bar-title">
			Предмети
		</portal>

		<v-row>
			<v-col
				v-for="subject in subjects"
				:key="subject.id"
				cols="12"
				sm="6"
				md="4"
				lg="3">
				<v-card
					:to="{
						name: 'subject',
						params: {
							id: subject.id,
						},
					}"
					class="elevation-24"
					height="100%">
					<v-card-title>
						{{ subject.name }}
					</v-card-title>

					<v-card-text>
						
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import Subject from '../models/Subject'

export default {
	computed: {
		subjects() {
			return Subject.query()
				.withAll()
				.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadSubjects() {
			try {
				await Subject.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
				throw error
			}
		},
	},
	mounted() {
		this.loadSubjects()
	},
}
</script>